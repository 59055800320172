import { format, parseISO } from 'date-fns'

export const buildDateFromEpoch = (epoch: number) => {
  const date = new Date(epoch * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const niceDuration = (inputSeconds?: number | null) => {
  if (!inputSeconds) {
    return '--:--'
  }

  const hours = Math.floor(inputSeconds / 3600)
  const minutes = Math.floor((inputSeconds - hours * 3600) / 60)
  const seconds = Math.floor(inputSeconds - hours * 3600 - minutes * 60)

  let string = ''
  if (hours) {
    if (hours < 10) {
      string += '0' + hours
    } else {
      string += hours
    }
    string += ':'
  }

  if (minutes < 10) {
    string += '0' + minutes
  } else {
    string += minutes
  }
  string += ':'

  if (seconds < 10) {
    string += '0' + seconds
  } else {
    string += seconds
  }

  return string
}

export const daysSinceADate = (datestring: string) => {
  const older = new Date(datestring)

  const now = new Date()

  const msDiff = now.getTime() - older.getTime()

  return Math.ceil(msDiff / (1000 * 3600 * 24))
}

export const daysUntilADate = (futureDateString: string, pastDateString?: string) => {
  const future = new Date(futureDateString)

  const now = pastDateString ? new Date(pastDateString) : new Date()

  const msDiff = future.getTime() - now.getTime()

  return Math.ceil(msDiff / (1000 * 3600 * 24))
}

/**
 * Formats a date string or timestamp into the specified format
 * @param date Date string, timestamp (in ms), or number (unix timestamp in seconds)
 * @param formatStr Format string following date-fns format (defaults to "MMM d, yyyy")
 * @returns Formatted date string
 */
export const formatDate = (date: string | number, formatStr = 'MMM d, yyyy') => {
  try {
    // If it's a number less than years around now, assume it's a unix timestamp in seconds
    const dateToFormat =
      typeof date === 'number' && date < 100000000000
        ? new Date(date * 1000)
        : typeof date === 'string'
          ? parseISO(date)
          : new Date(date)

    return format(dateToFormat, formatStr)
  } catch (error) {
    console.error('Error formatting date:', error)
    return 'Invalid date'
  }
}
